import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"

import Banner from "$components/Banner"
import ContactForm from "$components/ContactForm"
import Container from "$components/Container"
import Footer from "$components/Footer"
import Header from "$components/Header"
import SEO from "$components/SEO"

import bannerWordPress from "./assets/banner-wordpress.jpg"

const BannerContainer = styled(Container)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  margin-top: auto;

  @media only screen and ${props => props.theme.deviceSizes.mobile} {
    display: block;
  }
`

const ButtonLink = styled.button`
  background-color: ${props => props.theme.goldenDream};
  border-radius: 3px;
  border-bottom: outset;
  box-sizing: border-box;
  color: ${props => props.theme.tundora};
  display: block;
  font-size: 1.2rem;
  margin: 0 16px;
  padding: 8px 12px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  width: 100%;

  :first-child {
    margin-left: 0;
  }

  :hover {
    background-color: ${props => props.theme.darken(props.theme.goldenDream)};
  }

  :last-child {
    margin-right: 0;
  }

  @media only screen and ${props => props.theme.deviceSizes.mobile} {
    margin: 16px 0;

    :first-child {
      margin-top: 0;
    }

    :last-child {
      margin-bottom: 0;
    }
  }
`

const ContentContainer = styled(Container)`
  display: flex;

  @media only screen and ${props => props.theme.deviceSizes.tablet} {
    display: block;
  }
`

const ContentWrapper = styled.div`
  flex: 1;
  margin: 0 2rem;

  :first-child {
    margin-left: 0;
  }

  :last-child {
    margin-right: 0;
  }

  @media only screen and ${props => props.theme.deviceSizes.tablet} {
    margin: 0;
  }
`

const H2 = styled.h2`
  margin: 2.5rem 0;
  text-align: left;
`

const P = styled.p`
  margin: 2.5rem 0;
`

const StickyWrapper = styled(ContentWrapper)`
  align-self: flex-start;
  padding-bottom: 1rem;
  position: sticky;
  top: 0;

  @media only screen and ${props => props.theme.deviceSizes.tablet} {
    position: relative;
  }
`

const title = "Is your website secure? | Lotus Network Solutions"

export default () => {
  return (
    <>
      <SEO noindex title={title} />
      <Header />
      <main>
        <Banner bannerImage={bannerWordPress} height={400}>
          <BannerContainer>
            <h1>Is your website secure?</h1>
          </BannerContainer>
        </Banner>
        <ContentContainer>
          <ContentWrapper>
            <P>
              If you're here then chances are, you rely on a website for your
              business. It's more important than ever to make sure it's secure,
              so here are our three top tips for you to check off:
            </P>
            <H2>1. Are you using HTTPS?</H2>
            <P>
              One of the easiest issues to detect is a website’s use of HTTPS, a
              secure communication, rather than HTTP. Modern web browsers make
              this easily visible to users. For example, both Google Chrome and
              Firefox will display a “Not Secure” message for sites that are not
              fully secured. This can happen even if the URL starts with
              “https”! This can be due to a variety of reasons – the usual cause
              is a lack of an SSL (Secure Sockets Layer) certificate, or less
              often, if the HTTPS website tries to reach a non-HTTPS resource
              such as an image.
            </P>
            <P>
              Fortunately, these issues are often quite easy to solve. The
              majority of website builders such as Squarespace and Wix
              automatically enable SSL without any action required. WordPress, a
              widely used tool for building websites, can also have this
              functionality although the process can be less straightforward
              depending on where the site is hosted. WordPress plugins can also
              make the process of enabling HTTPS on all web links and images
              more automatic.
            </P>
            <H2>2. Are you up to date?</H2>
            <P>
              This point is more targeted to the WordPress users, as the other
              online platforms mentioned generally require less or no user
              updating. But both the WordPress software and the many plugins
              that are available receive security updates. WordPress’s
              popularity makes it a target for breaches, so checking your site’s
              security should be high on your to-do list.
            </P>
            <P>
              The solution here is easily accessible from your WordPress
              dashboard, as there are ways to have both WordPress and your
              plugins update automatically. Since version 3.7 of WordPress,
              released in 2013, the core software automatically updates, for one
              less update to worry about. We recommend not disabling this
              feature unless working with older plugins or themes that may not
              work with newer versions – although in that case we would
              suggesting finding more recent plugins! Plugins and themes do not
              update by default, but there are tools that can automate this too.
              Do consider that one reason to not automatically update the theme
              is that any custom changes may be erased, so be sure to back up
              before updating! Leading us to our third point:
            </P>
            <H2>3. Are you backed up?</H2>
            <P>
              Regardless of what platform you’re using, backups are a crucial
              part of online security. Imagine losing potential clients because
              they couldn’t access your website, if your content didn’t display
              correctly or the page didn’t load at all. Now imagine being able
              to restore any lost content within hours or minutes. A working
              backup solution is your safety net in today’s online environment,
              so take a moment to review what’s in place.
            </P>
            <P>
              Backup will look different for you depending on how your website
              is built, and maybe a full site restore isn’t possible with some
              providers. But remember that the main goal is to be able to
              restore as much data as possible, with as little downtime as
              possible, in the unlikely event of a data breach or data loss.
              This can even include user error, which can happen to anyone! If
              you’re unsure where to start, try keeping the text and images on
              your website in a second location, so that you can restore the
              content if needed. If you have access to the website files, such
              as with a WordPress installation, there are tools available to
              automatically and periodically back up your data, more convenient
              than manually creating copies.
            </P>
          </ContentWrapper>
          <StickyWrapper>
            <H2>Find out more</H2>
            <P>
              If you're interested in learning more about the areas of
              technology we specialise in, just click the buttons below.
            </P>
            <ButtonContainer>
              <ButtonLink as={Link} to="/services/">
                See all services &rarr;
              </ButtonLink>
              <ButtonLink as={Link} to="/insights/">
                Find resources{" "}
                <ion-icon name="document-text-outline" size="1rem" />
              </ButtonLink>
            </ButtonContainer>
            <H2>Contact us about website security today</H2>
            <P>
              Call us at <a href="tel:1300767428">1300 767 428</a> to chat with
              a Technology Adviser. Or ask us about any of the issues we've
              listed, or anything else you're concerned about.
            </P>
            <ContactForm />
          </StickyWrapper>
        </ContentContainer>
      </main>
      <Footer />
    </>
  )
}
